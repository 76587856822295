import React, { useEffect, useState, useRef } from "react";
import "./Portfolio.css";
import Cookies from "js-cookie";
import FundList from "../../components/FundList/FundList";
import ETagCache from "../../services/ETagCache";
import axios, { AxiosRequestConfig } from "axios";
import { useNavigate } from "react-router-dom";
import PersistScrollPosition from "../../services/PersistScrollPosition";
import { TChartShowClick } from "../../Router";


interface IPortfolioProps {
    onChartShow: TChartShowClick;
}


const Portfolio = (props: IPortfolioProps) => {
    const history = useNavigate();
    const [funds, setFunds] = useState([]);
    const [fundText, setFundText] = useState("Funds");
    const [serviceAlert, setServiceAlert] = useState(false);

    const updateScrollPosition = useRef(false);


    /**
     * Navigate to status page
     */
    const onClick = () => {
        history("/status");
    };


    useEffect(() => {
        ETagCache(axios as AxiosRequestConfig)({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
                    portfolio {
                        funds(orderBy: ytd_DESC) {
                            _id
                            lastUpdated
                            isin
                            name
                            yield
                            tradeSignal
                            fundRating
                            fundSizeInMillions
                            moneyFlow
                            dividendFrequency
                            ytd
                            netChangePercent
                            ongoingCharge
                            ratings {
                                morningstarRating
                            }
                            category {
                                name
                                ytd
                                rating
                                bestFund {
                                    _id
                                    name
                                    ytd
                                }
                            }
                            trailingPerformance {
                                M12
                                M36
                                M60
                            }
                            trailingStandardDeviation {
                                M12
                                M36
                                M60
                            }
                            medalistRating
                        }
                    }
                    status (type: ERROR) {
                        type
                    }
                }`
            }
        })
            .then(response => {
                if (!response.data.data.portfolio.funds.length) setFundText("No funds selected");
                updateScrollPosition.current = true;
                setFunds(response.data.data.portfolio.funds);
                if (response.data.data.status.length) setServiceAlert(true);                                                // Show service alert
            })
            .catch(() => {
                history("/login");                                                                                          // Redirect to login
            });

    }, []);

    return (
        <div id="pagewrapper">
            <div id="statuswarning" className={serviceAlert ? "show" : "hide"} onClick={onClick}>SERVICE ERROR</div>

            <h2>{fundText}</h2>
            <FundList funds={funds} onChartShow={props.onChartShow} />
            <PersistScrollPosition updateScrollPosition={updateScrollPosition.current} />
        </div>
    );

};

export default Portfolio;
