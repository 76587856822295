import Cookies from "js-cookie";
import "./FundItem.css";
import { StoreScrollPosition } from "../../services/PersistScrollPosition";
import { IFundItem } from "../models";
import { TChartShowClick } from "../../Router";
import React, { MouseEvent } from "react";
import { useNavigate } from "react-router-dom";


interface IFundItemProps {
    viewType?: string;
    fund: IFundItem;
    onChartShow: TChartShowClick;
}



/**
 * Called from: 
 * Category Ratings: no category button
 * Portfolio: category button
 */
const FundItem = (props: IFundItemProps) => {
    const history = useNavigate();

    const {                                                                                                                 // Destructure
        viewType,
        fund: {
            name,
            isin,
            tradeSignal,
            ongoingCharge,
            fundSizeInMillions,
            moneyFlow,
            ratings,
            category,
            lastUpdated,
            _id,
            ytd,
            netChangePercent,
            fundRating,
            dividendFrequency,
            trailingPerformance,
            trailingStandardDeviation,
            medalistRating
        }
    } = props;



    /** 
     * Redirect to category fund list
     * @param  {string} category
     */
    const onShowCategory = (category: string) => {
        StoreScrollPosition();
        history("/category/" + encodeURIComponent(category));
    };



    /** 
     * Show chart
     * @param  {string} fundId
     */
    const onShowChart = (e: MouseEvent, fundId: string) => {
        e.stopPropagation();
        props.onChartShow(fundId);
    };


    /** 
     * Compare 2 funds in one chart
     * @param  {string} sourceFundId
     * @param  {string} targetFundId
     */
    const onShowCompare = (e: MouseEvent, sourceFundId: string, targetFundId: string) => {
        e.stopPropagation();
        props.onChartShow(sourceFundId, targetFundId);
    };



    /**
     * Render the category
     */
    const renderCategory = () => {
        if (viewType === "category") {
            return category?.name;
        }
        else {
            return (
                <p>
                    <img alt="" onClick={() => onShowCategory(category?.name)} src="/images/list.png" />{category?.name}
                </p>
            );
        }
    };



    /**
     * Show analysis report for fund
     * 
     * @param  fundId
     */
    const onShowAnalystReportClick = (fundId: string) => {
        window.open(`${process.env.REACT_APP_SERVICE_HOST}/v1/reports/analystrating/${fundId}?sid=${Cookies.get("sid")}`, "_self");
    };



    /**
     * Add trend indicator
     */
    const trendIndicator = () => {
        if (tradeSignal === "BUY") {                                                                                        // Bull market
            return (
                <img className="trend-indicator" alt='' src='/images/uparrowgreen.png' />
            );
        }
        else return (                                                                                                       // Bear market
            <img className="trend-indicator" alt='' src='/images/downarrowred.png' />
        );
    };



    /**
     * Render "Better Fund" element
     */
    const renderBetterFund = () => {
        if (category?.bestFund && category?.bestFund.name !== name) {
            return (
                <tr>
                    <td className="key">
                        Better Fund
                    </td>
                    <td className="value">
                        <p >
                            <img alt="" onClick={(e) => onShowCompare(e, _id, category.bestFund._id)} src="/images/graph.png" />{category.bestFund.name} [{category.bestFund.ytd.toFixed(2)}%]
                        </p>
                    </td>
                </tr>
            );
        }
        else return;
    };



    /**
     * Add day change
     */
    const dayChange = () => {
        if (netChangePercent === null) return;
        if (netChangePercent > 0) {                                                                                         // > 0%
            return (
                <span className='price-up'>(+{netChangePercent.toFixed(2)}%)<img alt='' src='/images/uparrowgreen.png' /></span>
            );
        }
        else if (netChangePercent < 0) {                                                                                    // < 0%
            return (
                <span className='price-down'>({netChangePercent.toFixed(2)}%)<img alt='' src='/images/downarrowred.png' /></span>
            );
        }
        else                                                                                                                // 0.00%
            return (
                <span className='price-up'>({netChangePercent.toFixed(2)}%)</span >
            );
    };



    /**
     * Add fund size change
     * @returns 
     */
    const moneyFlowChange = () => {
        if (moneyFlow === null) return;
        if (moneyFlow > 0) {                                                                                                // > 0%
            return (
                <span className='price-up'>+{moneyFlow.toFixed(2)}%<img alt='' src='/images/uparrowgreen.png' /></span>
            );
        }
        else if (moneyFlow < 0) {                                                                                           // < 0%
            return (
                <span className='price-down'>{moneyFlow.toFixed(2)}%<img alt='' src='/images/downarrowred.png' /></span>
            );
        }
        else                                                                                                                // 0.00%
            return (
                <span className='price-up'>{moneyFlow.toFixed(2)}%</span >
            );
    };



    /**
     * Create trailing performance display
     */
    const trailingPerformanceDisplay = () => {
        return (
            <div>
                <div style={{ width: "80px", display: "inline-block" }}>
                    <span className="bold-text">1Y</span> {trailingPerformance.M12 ? `${trailingPerformance.M12.toFixed(2)}%` : "-"}
                </div>
                <div style={{ width: "80px", display: "inline-block" }}>
                    <span className="bold-text">3Y</span> {trailingPerformance.M36 ? `${trailingPerformance.M36.toFixed(2)}%` : "-"}
                </div>
                <div style={{ width: "80px", display: "inline-block" }}>
                    <span className="bold-text">5Y</span> {trailingPerformance.M60 ? `${trailingPerformance.M60.toFixed(2)}%` : "-"}
                </div>
            </div >
        );
    };



    /**
     * Create trailing standard deviation display
     */
    const trailingStandardDeviationDisplay = () => {
        return (
            <div>
                <div style={{ width: "80px", display: "inline-block" }}>
                    <span className="bold-text">1Y</span> {trailingStandardDeviation.M12 ? `${trailingStandardDeviation.M12.toFixed(2)}%` : "-"}
                </div>
                <div style={{ width: "80px", display: "inline-block" }}>
                    <span className="bold-text">3Y</span> {trailingStandardDeviation.M36 ? `${trailingStandardDeviation.M36.toFixed(2)}%` : "-"}
                </div>
                <div style={{ width: "80px", display: "inline-block" }}>
                    <span className="bold-text">5Y</span> {trailingStandardDeviation.M60 ? `${trailingStandardDeviation.M60.toFixed(2)}%` : "-"}
                </div>
            </div>
        );
    };


    return (
        <>
            <tr>
                <th colSpan={2}>
                    <div>{name}</div>
                </th>
            </tr>

            <tr>
                <td className="key">
                    ISIN
                </td>
                <td className="value">
                    {isin}
                </td>
            </tr>

            <tr>
                <td className="key">
                    Trend
                </td>
                <td className="value">
                    <p>{trendIndicator()}</p>
                </td>
            </tr>

            <tr>
                <td className="key">
                    Date
                </td>
                <td className="value">
                    {lastUpdated} {dayChange()}
                </td>
            </tr>

            <tr>
                <td className="key">
                    Fund YTD
                </td>
                <td className="value">
                    <p>
                        <img alt="" src={`/images/${fundRating || "na"}.jpg`} />
                        <img alt="" onClick={(e) => onShowChart(e, _id)} src="/images/graph.png" />{`${ytd.toFixed(2)}%`}
                    </p>
                </td>
            </tr>

            <tr>
                <td className="key">
                    Category
                </td>
                <td className="value">
                    {renderCategory()}
                </td>
            </tr>

            <tr>
                <td className="key">
                    Category YTD
                </td>
                <td className="value">
                    <p>
                        <img alt="" src={`/images/${category?.rating || "na"}.jpg`} />{category?.ytd ? `${category?.ytd.toFixed(2)}%` : "n/a"}
                    </p>
                </td>
            </tr>

            <tr>
                <td className="key">
                    Morningstar
                </td>
                <td className="value">
                    <p>
                        <img alt="" src={`/images/${ratings.morningstarRating || "na"}.jpg`} />
                        <img alt="" src="/images/report.png" onClick={() => onShowAnalystReportClick(_id)} />
                    </p>
                </td>
            </tr>

            {
                !!medalistRating &&
                <tr>
                    <td className="key">
                        MS Medalist
                    </td>
                    <td className="value">
                        <p>
                            <img alt="" src={`/images/${props.fund.medalistRating.toLowerCase()}.png`} style={{ marginLeft: "7px" }} />{props.fund.medalistRating}
                        </p>
                    </td>
                </tr>
            }

            {
                (!!dividendFrequency && dividendFrequency !== "None" && props.fund.yield !== 0) &&
                <tr>
                    <td className="key">
                        Yield
                    </td>
                    <td className="value">
                        {`${props.fund.yield.toFixed(2)}% ${dividendFrequency}`}
                    </td>
                </tr>
            }

            {
                !!ongoingCharge &&
                <tr>
                    <td className="key">
                        Ongoing Cost
                    </td>
                    <td className="value">
                        {`${ongoingCharge.toFixed(2)}%`}
                    </td>
                </tr>
            }

            {(!!fundSizeInMillions) &&
                <tr>
                    <td className="key">
                        Fund Size
                    </td>
                    <td className="value">
                        {`£${fundSizeInMillions.toLocaleString()}m`}
                    </td>
                </tr>
            }

            {(!!moneyFlow) &&
                <tr>
                    <td className="key">
                        Money Flow
                    </td>
                    <td className="value">
                        {moneyFlowChange()}
                    </td>
                </tr>
            }

            {
                !!trailingPerformance.M12 &&
                <tr>
                    <td className="key">
                        Trailing Return
                    </td>
                    <td className="value">
                        {trailingPerformanceDisplay()}
                    </td>
                </tr>
            }

            {
                !!trailingStandardDeviation.M12 &&
                <tr>
                    <td className="key">
                        Std Deviation
                    </td>
                    <td className="value">
                        {trailingStandardDeviationDisplay()}
                    </td>
                </tr>
            }

            {renderBetterFund()}
        </>
    );

};

export default FundItem;
