import React, { FormEvent, useState } from "react";
import Autosuggest, { SuggestionSelectedEventData } from "react-autosuggest";
import "./Search.css";
import axios from "axios";
import FundList from "../../components/FundList/FundList";
import parse from "html-react-parser";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import { IFundItem } from "../../components/models";
import { TChartShowClick } from "../../Router";


interface ISearchProps {
    onChartShow: TChartShowClick;
}


const Search = (props: ISearchProps) => {

    const [funds, setFunds] = useState([]);
    const [value, setValue] = useState("");
    const [suggestions, setSuggestions] = useState([]);
    const history = useNavigate();


    /**
     * Get suggestions for value
     * 
     * @param  value
     */
    const getSuggestions = async (value: string) => {
        const response = await axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
                    funds (name: "${value}", limit: 20) {
                        _id
                        name
                    }
                }`
            }
        });


        // check handling invalid characters!

        if (response.data.errors) {
            history("/login");
        }

        console.log("Loaded search funds", response.data.data.funds.length);

        return response.data;
    };


    /**
     * On suggestion selection, update input
     *
     * @param  suggestion
     */
    const getSuggestionValue = (suggestion: IFundItem) => suggestion.name;


    /**
    * Mark value in suggestion as <strong>
    *
    * @param  value
    * @param  textValue
    */
    const highlightSearch = (value: string, textValue: string) => {
        return parse(textValue.replace(new RegExp("(" + value + ")", "gi"), "<strong>$1</strong>"));
    };


    /**
    * Render suggestion
    *
    * @param  suggestion
    */
    const renderSuggestion = (suggestion: IFundItem) => (
        <div>
            {highlightSearch(value, suggestion.name)}
        </div>
    );


    /**
    * On Change event
    *
    * @param  event
    * @param  newValue
    */
    const onChange = (event: FormEvent<HTMLElement>, { newValue }: { newValue: string; }) => {
        setValue(newValue);
    };



    const onSuggestionsFetchRequested = ({ value }: { value: string; }) => {
        getSuggestions(value)
            .then(data => {
                if (data.Error) {
                    setSuggestions([]);
                }
                else {
                    if (data.data.funds.length === 1) {                 // Automatically select first one
                        getFund(data.data.funds[0]._id);
                        onSuggestionsClearRequested();
                    }
                    else setSuggestions(data.data.funds);
                }
            });
    };


    const onSuggestionsClearRequested = () => {
        setValue("");
        setSuggestions([]);
    };


    // Default value
    const inputProps = {
        placeholder: "Type fund name",
        value,
        autoFocus: true,
        onChange
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const onSuggestionSelected = (event: FormEvent<any>, { suggestion }: SuggestionSelectedEventData<IFundItem>) => {
        event.stopPropagation();
        getFund(suggestion._id);
    };


    const getFund = (fundId: string) => {
        axios({
            method: "POST",
            url: `${process.env.REACT_APP_SERVICE_HOST}/graphql`,
            headers: {
                Authorization: `Bearer ${Cookies.get("sid")}`
            },
            data: {
                query: `{
                    funds (_id: "${fundId}") {
                        _id
                        lastUpdated
                        name
                        yield
                        isin
                        tradeSignal
                        fundRating
                        dividendFrequency
                        fundSizeInMillions
                        moneyFlow
                        ytd
                        netChangePercent
                        ongoingCharge
                        ratings {
                            morningstarRating
                        }
                        category {
                            name
                            ytd
                            rating
                        }
                        trailingPerformance {
                            M12
                            M36
                            M60
                        }
                        trailingStandardDeviation {
                            M12
                            M36
                            M60
                        }
                        medalistRating
                    }
                }`
            }
        })
            .then(response => {
                console.log("Load fund", response.data.data.funds.length);
                setFunds(response.data.data.funds);
            })
            .catch(() => {
                history("/login");
            });
    };

    return (
        <div id="pagewrapper" className="searchpanel" >
            <div id="search">
                <div className="shadow">
                    <img alt="" src="/images/search.png" />
                    <Autosuggest
                        suggestions={suggestions}
                        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
                        onSuggestionsClearRequested={onSuggestionsClearRequested}
                        onSuggestionSelected={onSuggestionSelected}
                        getSuggestionValue={getSuggestionValue}
                        renderSuggestion={renderSuggestion}
                        inputProps={inputProps}
                    />
                </div>
            </div>

            <FundList funds={funds} onChartShow={props.onChartShow} />
        </div>
    );

};

export default Search;
